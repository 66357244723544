import dedent from "dedent";
import type { NotificationData, NotificationText } from "../supabase/ports/index.ts";
import type { Database } from "../types/supabase.gen.ts";
import { getFullName } from "./people.ts";
import { translateDefinitiveEndType } from "./translate.ts";
import type { BackOfficeNotifications, MobileAppNotifications } from "../supabase/ports/notification.ts";
export type { NotificationData, NotificationText };

/**
 * Notification Content
 * ------------------
 * This file handles the formatting of notifications for both mobile app and back office.
 *
 * Structure:
 * 1. Type definitions for notification formatters - we separate mobile app and back office.
 * 2. Helper functions for formatting text and links
 * 3. Main generateNotificationText function that will look up on the maps (back-office formatters & mobile app formatters)
 * 4. Mobile app notification formatters (SMS-style notifications)
 * 5. Back office notification formatters (HTML-style notifications with links)
 *
 */

const EMPTY = "-";

// Types the function used for format, with a generic Type argument
type FormatterFn<Type> = (
  notificationData: Extract<NotificationData, { type: Type }>["data"],
  baseUrl?: string
) => NotificationText;

// Extract Type and gives it to the FormatterFn, for mobile app and BackOffice
type MobileAppNotificationFormatters = {
  [Type in Extract<MobileAppNotifications["type"], string>]: FormatterFn<Type>;
};
type BackOfficeNotificationFormatters = {
  [Type in Extract<BackOfficeNotifications["type"], string>]: FormatterFn<Type>;
};

const formatOptionalValueWithFunc = (value: unknown, func: (...args: any[]) => string) => {
  return value ? func(value) : EMPTY;
};

const formatLink = (path: string, text: string, baseUrl = "") =>
  baseUrl ? `<a href="${baseUrl}${path}">${text}</a>` : text;

export const generateNotificationText = ({ type, data }: NotificationData, baseUrl?: string): NotificationText => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const { title, body } = notificationTextFormatters[type](data as any, baseUrl);

  return {
    title,
    body: dedent(body),
  };
};

const mobileAppNotificationTextFormatters: MobileAppNotificationFormatters = {
  mobile_app__talent_contract_signed: ({ client_name: clientName, isPushNotification }) => {
    const smsBody = `Ton contrat chez ${clientName} est bien signé. Retrouve-le dans tes documents sur l'application. STOP au [STOP_CODE]`;
    const pushNotificationBody = `Ton contrat chez ${clientName} est bien signé. `;

    const body = isPushNotification ? pushNotificationBody : smsBody;
    return {
      title: "Contrat signé",
      body,
    };
  },

  mobile_app__talent_timesheet_reminder: ({
    week_number: weekNumber,
    has_auth_access: hasAuthAccess,
    navigateTo,
    isPushNotification,
  }) => {
    const smsBody = `Ton relevé d'heures (semaine ${weekNumber}) n'est toujours pas signé. ${
      hasAuthAccess ? `Retrouve le ici: ${navigateTo}` : "Télécharge l'application asap.work pour le signer"
    }. STOP au [STOP_CODE]`;
    const pushNotificationBody = `Ton relevé d'heures (semaine ${weekNumber}) n'est toujours pas signé.`;

    const body = isPushNotification ? pushNotificationBody : smsBody;
    return {
      title: "Relevé d'heures à signer",
      body,
    };
  },

  mobile_app__timesheet_fully_approved: ({
    client_name: clientName,
    week_number: weekNumber,
    navigateTo,
    isPushNotification,
  }) => {
    const smsBody = `Ton relevé d'heures (semaine ${weekNumber}) a été approuvé par ${clientName}. Retrouve le ici: ${navigateTo}. STOP au [STOP_CODE]`;
    const pushNotificationBody = `Ton relevé d'heures (semaine ${weekNumber}) a été approuvé par ${clientName}.`;

    const body = isPushNotification ? pushNotificationBody : smsBody;
    return {
      title: "Relevé d'heures approuvé",
      body,
    };
  },

  mobile_app__timesheet_rejected_by_client: ({
    client_name: clientName,
    week_number: weekNumber,
    navigateTo,
    isPushNotification,
  }) => {
    const smsBody = `Ton relevé d'heures (semaine ${weekNumber}) n'a pas été validé par ${clientName}. Modifie le ici: ${navigateTo}. STOP au [STOP_CODE]`;
    const pushNotificationBody = `Ton relevé d'heures (semaine ${weekNumber}) n'a pas été validé par ${clientName}.`;

    const body = isPushNotification ? pushNotificationBody : smsBody;
    return {
      title: "Relevé d'heures refusé par le client",
      body,
    };
  },

  mobile_app__expense_claim_approved: ({ amount, isPushNotification }) => {
    const smsBody = `Ta note de frais de ${amount}€ a été approuvée.  Retrouve la sur l'application. STOP au [STOP_CODE]`;
    const pushNotificationBody = `Ta note de frais de ${amount}€ a été approuvée.`;

    const body = isPushNotification ? pushNotificationBody : smsBody;
    return {
      title: "Note de frais approuvée",
      body,
    };
  },

  mobile_app__expense_claim_refused: ({ amount, isPushNotification }) => {
    const smsBody = `Ta note de frais de ${amount}€ a été refusée. Retrouve la sur l'application. STOP au [STOP_CODE]`;
    const pushNotificationBody = `Ta note de frais de ${amount}€ a été refusée. `;

    const body = isPushNotification ? pushNotificationBody : smsBody;
    return {
      title: "Note de frais refusée",
      body,
    };
  },

  mobile_app__talent_contract_unsigned_reminder: ({
    company_name: companyName,
    has_auth_access: hasAuthAccess,
    navigateTo,
    isPushNotification,
  }) => {
    const smsBody = `Ton contrat ${companyName ? `chez ${companyName}` : ``} n'est pas signé. ${
      hasAuthAccess ? `Retrouve le ici: ${navigateTo}` : "Télécharge l'application asap.work pour le retrouver"
    }. STOP au [STOP_CODE]`;
    const pushNotificationBody = `Ton contrat ${companyName ? `chez ${companyName}` : ``} n'est pas signé.`;

    const body = isPushNotification ? pushNotificationBody : smsBody;
    return {
      title: "Contrat à signer",
      body,
    };
  },

  mobile_app__expense_claim_canceled: ({ amount, isPushNotification }) => {
    const smsBody = `Ta note de frais de ${amount}€ a été annulée. Retrouve la sur l'application. STOP au [STOP_CODE]`;
    const pushNotificationBody = `Ta note de frais de ${amount}€ a été annulée. `;

    const body = isPushNotification ? pushNotificationBody : smsBody;
    return {
      title: "Note de frais annulée",
      body,
    };
  },
};

const backOfficeNotificationTextFormatters: BackOfficeNotificationFormatters = {
  first_contract_signed_by_talent: ({ navigateTo, talent, owner, contract }, baseUrl) => ({
    title: `🚀 Premier contrat pour ${formatOptionalValueWithFunc(talent, getFullName)}`,
    body: `
  ${formatLink(navigateTo, "Le contrat", baseUrl)} :
    - Consultant : ${owner.full_name}
    - Date de début de mission : ${contract.start_date}
    - Entreprise : ${contract.agency?.name} (${contract.agency?.formatted_address || EMPTY})
    - Métier : ${contract.job_name}

  ${formatLink(`/talents/${talent.id}`, "L'intérimaire", baseUrl)} :
    - Date de naissance : ${talent.birth_date}
    - Email : ${talent.email}
    - Téléphone : ${talent.phone_number}
    - Adresse : ${talent.address?.formatted}
  `,
  }),
  approved_recruitment_office_invoice_request: ({ navigateTo }, baseUrl) => ({
    title: `💸 Une facture CAB a été validée`,
    body: formatLink(navigateTo, "Facture finale à créer", baseUrl),
  }),
  failed_advance_payment_request: ({ navigateTo, talent, creation_date: creationDate }, baseUrl) => ({
    title: "❌ Échec de la demande d'acompte sur salaire !",
    body: `Vérifier ${formatLink(navigateTo, "la demande", baseUrl)} lié à l'adresse e-mail ${talent.email || EMPTY} datant du ${creationDate}`,
  }),
  done_recruitment_office_invoice_request: ({ navigateTo }, baseUrl) => ({
    title: "💸 Ta facture CAB finale est prête !",
    body: `Tu peux la consulter en ${formatLink(navigateTo, "cliquant ici", baseUrl)}`,
  }),
  pending_recruitment_office_invoice_request: ({ navigateTo }, baseUrl) => ({
    title: "💸 Validez votre facture CAB !",
    body: `Vous avez un ${formatLink(navigateTo, "brouillon de facture CAB", baseUrl)} en attente de validation.`,
  }),
  recruitment_office_payment_paid: ({ navigateTo, amount, companyName, talentFullName, isPercentage }, baseUrl) => ({
    title: "💸 Paiement reçu pour un de tes CAB",
    body: `Un paiement de ${amount} ${isPercentage ? "% des honoraires" : "€"} a été effectué pour le CAB entre ${talentFullName} et l'entreprise ${companyName}. ${formatLink(navigateTo, "Clique ici", baseUrl)}`,
  }),
  new_advance_payment_request: ({ navigateTo, talent, team_member: teamMember }, baseUrl) => ({
    title: `💸 Nouvelle demande d'acompte sur salaire à traiter pour ${formatOptionalValueWithFunc(talent, getFullName)} !`,
    body: `Créée par ${formatOptionalValueWithFunc(teamMember, getFullName)} : ${formatLink(navigateTo, "clique ici", baseUrl)}`,
  }),
  new_contract_to_process: ({ navigateTo, is_amendment: isAmendment, team_member: teamMember, talent }, baseUrl) => ({
    title: `🔥 Nouvelle demande ${isAmendment ? "d'avenant" : "de contrat"} pour ${formatOptionalValueWithFunc(talent, getFullName)} !`,
    body: `Créé par ${formatOptionalValueWithFunc(teamMember, getFullName)} : ${formatLink(navigateTo, "clique ici", baseUrl)}`,
  }),
  new_contract_to_send: ({ navigateTo, talent, is_amendment: isAmendment }, baseUrl) => ({
    title: `💌 Le ${isAmendment ? "nouvel avenant" : "nouveau contrat"} de ${formatOptionalValueWithFunc(talent, getFullName)} est prêt à être envoyé !`,
    body: `${formatLink(navigateTo, "Clique ici", baseUrl)} pour envoyer à l'entreprise.`,
  }),
  new_definitive_end_on_contract_to_process: ({ navigateTo, owner, talent }, baseUrl) => ({
    title: `👋 Nouvelle demande de fin définitive pour ${formatOptionalValueWithFunc(talent, getFullName)}`,
    body: `Créée par ${formatOptionalValueWithFunc(owner, getFullName)}: ${formatLink(navigateTo, "clique ici", baseUrl)}.`,
  }),
  new_expense_claim_request_to_validate: ({ navigateTo, team_member: teamMember }, baseUrl) => ({
    title: `🖍️ Nouvelle demande de note de frais à valider pour ${formatOptionalValueWithFunc(teamMember, getFullName)}`,
    body: `La consulter ici : ${formatLink(navigateTo, "clique ici", baseUrl)}`,
  }),
  new_expense_claim_request_to_process: ({ navigateTo, talentFullName, team_member: teamMember }, baseUrl) => ({
    title: `🤑 Nouvelle demande de note de frais pour le talent ${formatOptionalValueWithFunc(talentFullName, getFullName)} !`,
    body: `Créé par ${formatOptionalValueWithFunc(teamMember, getFullName)} : ${formatLink(navigateTo, "clique ici", baseUrl)}`,
  }),
  new_medical_checkup_request: ({ navigateTo, talent, team_member: teamMember }, baseUrl) => ({
    title: `🩺 Nouvelle demande de visite médicale à traiter pour ${formatOptionalValueWithFunc(talent, getFullName)} !`,
    body: `Créée par ${formatOptionalValueWithFunc(teamMember, getFullName)} : ${formatLink(navigateTo, "clique ici", baseUrl)} `,
  }),
  new_onboarding_app_needed: ({ navigateTo, talent, owner }, baseUrl) => ({
    title: `📱 Demande d'onboarding app pour ${formatOptionalValueWithFunc(talent, getFullName)}`,
    body: `Une ${formatLink(navigateTo, "nouvelle demande d'onboarding", baseUrl)} vient d'être créée par ${formatOptionalValueWithFunc(owner, getFullName)}`,
  }),
  new_professional_card_request: ({ navigateTo, talent, team_member: teamMember }, baseUrl) => ({
    title: `🪪 Nouvelle demande de carte BTP à traiter pour ${formatOptionalValueWithFunc(talent, getFullName)} !`,
    body: `Créée par ${formatOptionalValueWithFunc(teamMember, getFullName)} : ${formatLink(navigateTo, "clique ici", baseUrl)}`,
  }),
  new_qualification_training_request: ({ navigateTo, talent, team_member: teamMember }, baseUrl) => ({
    title: `🎓 Nouvelle demande d'habilitation à traiter pour ${formatOptionalValueWithFunc(talent, getFullName)}  !`,
    body: `Créée par ${formatOptionalValueWithFunc(teamMember, getFullName)} : ${formatLink(navigateTo, "clique ici", baseUrl)} `,
  }),
  new_recruitment_office_invoice_request: ({ navigateTo }, baseUrl) => ({
    title: "💸 Création de CAB ! ",
    body: `${formatLink(navigateTo, "Ajoutez une facture", baseUrl)} pour relecture par le consultant`,
  }),
  refused_recruitment_office_invoice_request: ({ navigateTo }, baseUrl) => ({
    title: "💸 Brouillon de facture CAB refusée !",
    body: `${formatLink(navigateTo, "Cliquer ici pour la modifier", baseUrl)} la modifier.`,
  }),
  talent_offboarding_needed: ({ navigateTo, contract, owner_full_name: ownerFullName, talent }, baseUrl) => ({
    title: `💔 Fin définitive de ${formatOptionalValueWithFunc(talent, getFullName)}`,
    body: `
  ${formatLink(navigateTo, "Le contrat", baseUrl)} :
    - Consultant : ${ownerFullName}
    - Date de fin de mission prévu : ${contract.end_date}
    - Date de fin de mission réel : ${contract.real_end_date}
    - Raison de la fin définitive : ${contract.definitive_end_type ? translateDefinitiveEndType.fr[contract.definitive_end_type as Database["public"]["Enums"]["definitive_end_type"]] : "Inconnu"}
      - Détails (optionnel) : ${contract.definitive_end_details || EMPTY}

  ${formatLink(`/talents/${talent.id}`, "L'intérimaire", baseUrl)} :
    - Email : ${talent.email}
    - Adresse : ${talent.address?.formatted}
  `,
  }),
  new_qualification_training_request_invoicing_document: (
    { navigateTo, qualificationCenterName, talent },
    baseUrl
  ) => ({
    title: `🎓 Nouveau document de facturation liée à une demande d'habilitation`,
    body: `${formatLink(navigateTo, "Les factures de la formation", baseUrl)} de ${formatOptionalValueWithFunc(talent, getFullName)} du centre de formation ${qualificationCenterName} sont disponibles.`,
  }),
  new_qualification_training_request_certificate_document: ({ navigateTo, talent }, baseUrl) => ({
    title: `🎓 Nouveau certificat liée à une demande d'habilitation`,
    body: `${formatLink(navigateTo, "Le certificat de formation", baseUrl)} de ${formatOptionalValueWithFunc(talent, getFullName)} est disponible.`,
  }),
  cancel_medical_checkup_request_on_definitive_contract_end: (
    { navigateTo, medicalCenterName, status, talent, wsm },
    baseUrl
  ) => ({
    title: `❌ Nouvelle demande de visite médicale ${status === "approved" ? "à annuler" : "annulée"}`,
    body:
      status === "todo" || status === "pending"
        ? `La fin définitive de contrat de ${formatOptionalValueWithFunc(talent, getFullName)} a été validée et ${formatLink(navigateTo, "une demande de visite médicale", baseUrl)} avait été initiée, elle est désormais annulée:
          - WSM : ${formatOptionalValueWithFunc(wsm, getFullName)}`
        : `Une fin définitive de contrat vient d'être validée pour ${formatOptionalValueWithFunc(talent, getFullName)} et ${formatLink(navigateTo, "une de visite médicale", baseUrl)} est prévue veuillez l'annuler:
          - WSM : ${formatOptionalValueWithFunc(wsm, getFullName)}
          - Centre de visite médicale : ${medicalCenterName}`,
  }),
  cancel_ppe_request_on_definitive_contract_end: ({ navigateTo, talent, status }, baseUrl) => ({
    title: `❌ Nouvelle demande d'EPI ${status === "approved" ? "à refuser" : "refusée"}`,
    body:
      status === "todo" || status === "pending"
        ? `La fin définitive de contrat de ${formatOptionalValueWithFunc(talent, getFullName)} a été validée et ${formatLink(navigateTo, "une demande d'EPI", baseUrl)} avait été initiée, elle est désormais refusée.`
        : `Une fin définitive de contrat vient d'être validée pour ${formatOptionalValueWithFunc(talent, getFullName)} et ${formatLink(navigateTo, "une demande d'EPI", baseUrl)} est prévue veuillez la refuser.`,
  }),
  cancel_qualif_train_req_on_definitive_contract_end: ({ navigateTo, talent, qualificationCenterName }, baseUrl) => ({
    title: `❌ Nouvelle demande d'habilitation à annuler`,
    body: `Une fin définitive de contrat vient d'être validée pour ${formatOptionalValueWithFunc(talent, getFullName)} et ${formatLink(navigateTo, "une demande d'habilitation", baseUrl)} du centre de formation ${qualificationCenterName} est prévue veuillez l'annuler.`,
  }),
  end_of_company_insurance_request: ({ navigateTo, status }, baseUrl) => ({
    title: `🛡️ Demande de garantie ${status.includes("approved") ? "acceptée" : "refusée"} !`,
    body: formatLink(navigateTo, "Le détail sur le BO", baseUrl),
  }),
  new_company_insurance_request: ({ navigateTo }, baseUrl) => ({
    title: `🛡️ Nouvelle demande de garantie !`,
    body: formatLink(navigateTo, "Envoyer à Groupama", baseUrl),
  }),
  failed_website_job_posting: ({ navigateTo }, baseUrl) => ({
    title: `❌ Échec de la demande de publication d'offre d'emploi sur le site !`,
    body: `Quelqu'un est probablement sur webflow, il faut sûrement republier le site en production (${formatLink(navigateTo, "la mission", baseUrl)}).`,
  }),
  first_contract_signed_by_independent: ({ navigateTo, owner_full_name: ownerFullName }, baseUrl) => ({
    title: `🚀 Premier contrat signé par l'indépendant ${ownerFullName}`,
    body: `${formatLink(navigateTo, "Félicitations", baseUrl)} ! Il est temps de partager avec lui nos process WSM.`,
  }),
  new_qualification_training_commitment: ({ navigateTo, talent, wsm }) => ({
    title: `🎓 Dédit de formation à faire remplir !`,
    body: `Convocation uploadée pour la demande de formation de <strong>${formatOptionalValueWithFunc(talent, getFullName)}</strong>.
  Pensez à lui faire remplir le dédit de formation : ${formatLink(navigateTo, "cliquez pour y accéder")}.
  Ceci est essentiel pour ce type de formation.
  Vous pouvez faire une copie du document afin de l'éditer.
  Une fois signée via YouSign, envoyez au WSM en charge de la demande: ${formatOptionalValueWithFunc(wsm, getFullName)}`,
  }),
  new_contract_document_pre_employment_declaration: ({ navigateTo, talent }, baseUrl) => ({
    title: `🎒 Nouvelle DPAE liée à un contrat !`,
    body: `Une ${formatLink(navigateTo, "nouvelle déclaration préalable à l'embauche", baseUrl)} a été uploadée :
  - Intérimaire : ${formatOptionalValueWithFunc(talent, getFullName)}`,
  }),
  new_mission_to_post_on_job_boards: ({ navigateTo, teamMember }, baseUrl) => ({
    title: `🙏 Une tit' multidiffusion pour ${formatOptionalValueWithFunc(teamMember, getFullName)} `,
    body: formatLink(navigateTo, "Clique ici", baseUrl),
  }),
  new_professional_card_request_document_uploaded: ({ navigateTo, talent }, baseUrl) => ({
    title: `🪪 Une nouvelle carte BTP a été uploadée suite à ta demande`,
    body: `Retrouve la carte BTP provisoire de ${formatOptionalValueWithFunc(talent, getFullName)} ${formatLink(navigateTo, "sur le back-office", baseUrl)}`,
  }),
  archived_job_offer: ({ navigateTo }, baseUrl) => ({
    title: `👁️ Une mission a été archivée`,
    body: `Retrouve la ${formatLink(navigateTo, "ici", baseUrl)}`,
  }),
  new_contract_signed: ({ navigateTo, talent, is_amendment: isAmendment, signer }, baseUrl) => ({
    title: `🍾 ${isAmendment ? "L'avenant" : "Le contrat"} de ${formatOptionalValueWithFunc(talent, getFullName)} vient d'être signé par ${signer} !`,
    body: `${formatLink(navigateTo, "Clique ici", baseUrl)} pour le consulter.`,
  }),
  new_contract_declined: ({ navigateTo, talent, is_amendment: isAmendment, signer, declineReason }, baseUrl) => ({
    title: `😭 ${isAmendment ? "L'avenant" : "Le contrat"} de ${formatOptionalValueWithFunc(talent, getFullName)} vient d'être refusé par ${signer}: "${declineReason}"!`,
    body: `${formatLink(navigateTo, "Clique ici", baseUrl)} pour le consulter.`,
  }),
  expiring_document: ({ navigateTo, talentFullName, documentType, expiredAt }, baseUrl) => ({
    title: `‼️ Le ${documentType} de ${talentFullName} ${expiredAt === "est expiré" ? expiredAt : "arrive à expiration " + expiredAt} !`,
    body: `${formatLink(navigateTo, "Clique ici", baseUrl)} pour consulter son profil.`,
  }),
  new_commercial_condition_created: ({ navigateTo, owner, workspace }, baseUrl) => ({
    title: `📝 Nouvelle condition commerciale ${workspace === "internal" ? "" : "à valider"}`,
    body: `Créée par ${formatOptionalValueWithFunc(owner, getFullName)} : ${formatLink(navigateTo, "Clique ici", baseUrl)}`,
  }),
  each_commercial_condition_validated: ({ navigateTo, company }, baseUrl) => ({
    title: `👌 Condition commerciale pour ${company} validée !`,
    body: `${formatLink(navigateTo, "Clique ici", baseUrl)} pour la consulter.`,
  }),
  commercial_conditions_is_signed: ({ navigateTo, company }, baseUrl) => ({
    title: `👌 Condition commerciale pour ${company} signée !`,
    body: `${formatLink(navigateTo, "Clique ici", baseUrl)} pour la consulter.`,
  }),
  commercial_conditions_is_declined: ({ navigateTo, company }, baseUrl) => ({
    title: `❌ Condition commerciale pour ${company} refusée !`,
    body: `${formatLink(navigateTo, "Clique ici", baseUrl)} pour la consulter.`,
  }),
  new_contract_to_validate: ({ navigateTo, owner }, baseUrl) => ({
    title: `📝 Nouveau contrat indépendant à valider`,
    body: `Un nouveau contrat a été créé par ${formatOptionalValueWithFunc(owner, getFullName)} : ${formatLink(navigateTo, "clique ici", baseUrl)}`,
  }),
  new_ppe_request: ({ navigateTo, team_member: teamMember, talent }, baseUrl) => ({
    title: `⛑️ Nouvelle demande d'EPI pour ${formatOptionalValueWithFunc(talent, getFullName)}`,
    body: `Créée par ${formatOptionalValueWithFunc(teamMember, getFullName)} : ${formatLink(navigateTo, "clique ici", baseUrl)}`,
  }),
  new_ppe_request_from_app: ({ navigateTo, team_member: teamMember, talent }, baseUrl) => ({
    title: `⛑️ Nouvelle demande d'EPI pour ${formatOptionalValueWithFunc(talent, getFullName)}`,
    body: `À vérifier et confirmer par ${formatOptionalValueWithFunc(teamMember, getFullName)} : ${formatLink(navigateTo, "clique ici", baseUrl)}`,
  }),
  new_ppe_request_validated: ({ navigateTo, team_member: teamMember, talent }, baseUrl) => ({
    title: `⛑️ Nouvelle demande d'EPI pour ${formatOptionalValueWithFunc(talent, getFullName)}`,
    body: `Confirmée par ${formatOptionalValueWithFunc(teamMember, getFullName)} : ${formatLink(navigateTo, "clique ici", baseUrl)}`,
  }),
  medical_checkup_request_pending: ({ navigateTo, talent }, baseUrl) => ({
    title: `🚑 La visite médicale de ${formatOptionalValueWithFunc(talent, getFullName)} est en cours de traitement !`,
    body: ` ${formatLink(navigateTo, "Clique ici", baseUrl)} pour voir le détail.`,
  }),
  medical_checkup_request_done: ({ navigateTo, talent }, baseUrl) => ({
    title: `👌 La visite médicale de ${formatOptionalValueWithFunc(talent, getFullName)} est terminée !`,
    body: ` ${formatLink(navigateTo, "Clique ici", baseUrl)} pour voir le détail.`,
  }),
  medical_checkup_request_approved: ({ navigateTo, talent, convocationDate }, baseUrl) => ({
    title: `🩺 La visite médicale de ${formatOptionalValueWithFunc(talent, getFullName)} est planifiée pour le ${convocationDate} et sa convocation est en ligne.`,
    body: ` ${formatLink(navigateTo, "Clique ici", baseUrl)} pour voir le détail.`,
  }),
  qualification_training_request_approved: ({ navigateTo, talent, convocationDate }, baseUrl) => ({
    title: `🎓 La demande d'habilitation de ${formatOptionalValueWithFunc(talent, getFullName)} est planifiée pour le ${convocationDate} et sa convocation est en ligne !`,
    body: ` ${formatLink(navigateTo, "Clique ici", baseUrl)} pour voir le détail.`,
  }),
  new_rib_uploaded: ({ navigateTo, talentFullName, uploadedByFullName }, baseUrl) => ({
    title: `💳 Un nouveau RIB a été uploadé`,
    body: `${uploadedByFullName} a uploadé un nouveau RIB pour ${talentFullName}. Voir les détails : ${formatLink(navigateTo, "Clique ici", baseUrl)}`,
  }),
  job_offer_republish: ({
    activeMissionArchived,
    activeMissionNotPublished,
    inactiveMissionPublished,
    activeMissionThatNeedToBeRepublished,
    totalActiveMissionThatNeedToBeRepublished,
  }) => ({
    title: `🔄 Republication des missions`,
    body: `
  - Missions actives non publiées : ${activeMissionNotPublished}
  - Missions actives archivées : ${activeMissionArchived}
  - Missions inactives publiées : ${inactiveMissionPublished}

  - Missions actives publiées y'a plus de 2 semaines qu'on va republier : ${activeMissionThatNeedToBeRepublished} / ${totalActiveMissionThatNeedToBeRepublished}
  `,
  }),
  new_request_push_marketing: ({ teamMember, navigateTo, talent }) => ({
    title: `🏋️‍♂️ Nouvelle demande de push marketing pour le talent ${formatOptionalValueWithFunc(talent, getFullName)} `,
    body: `Créée par ${formatOptionalValueWithFunc(teamMember, getFullName)} : <a href="${navigateTo}">clique ici</a>`,
  }),
  turned_off_push_marketing: ({ teamMember, navigateTo, talent }) => ({
    title: `✋ Demande pour désactiver un push marketing pour le talent ${formatOptionalValueWithFunc(talent, getFullName)}`,
    body: `Créée par ${formatOptionalValueWithFunc(teamMember, getFullName)} : <a href="${navigateTo}">clique ici</a>`,
  }),
  new_work_accident_request: ({ teamMember, navigateTo }, baseUrl) => ({
    title: "🚑 Nouvelle demande d'accident de travail",
    body: `Créée par ${formatOptionalValueWithFunc(teamMember, getFullName)} : ${formatLink(navigateTo, "Clique ici", baseUrl)}`,
  }),
  work_accident_request_done: ({ talent, navigateTo }, baseUrl) => ({
    title: "✅ Demande d'accident du travail traitée",
    body: `Votre demande d'accident de travail pour ${formatOptionalValueWithFunc(talent, getFullName)} a été traitée : ${formatLink(navigateTo, "Clique ici", baseUrl)}`,
  }),
  talent_documents_uploaded: ({ talentFullName, navigateTo }, baseUrl) => ({
    title: `📁 ${talentFullName} a uploadé tous ses documents !`,
    body: `Retrouve tous les documents de ${talentFullName} ${formatLink(navigateTo, "sur le back-office", baseUrl)}`,
  }),
  expense_claim_to_review: ({ talentFullName, navigateTo }, baseUrl) => ({
    title: `💰 Une note de frais a été créée par ${talentFullName} sur l'application mobile`,
    body: `Complète et valide la demande : ${formatLink(navigateTo, "Clique ici", baseUrl)}`,
  }),
  pay_tracking_completed: ({ talent, navigateTo, month }, baseUrl) => ({
    title: `🧾 La paie de ${formatOptionalValueWithFunc(talent, getFullName)} pour le mois de ${month} a été complétée, il est temps de la traiter`,
    body: `Retrouve la paie de ${formatOptionalValueWithFunc(talent, getFullName)} ${formatLink(navigateTo, "sur le back-office", baseUrl)}`,
  }),
  pay_tracking_processed: ({ talent, navigateTo, month }, baseUrl) => ({
    title: `🔎 La paie de ${formatOptionalValueWithFunc(talent, getFullName)} pour le mois de ${month} a été traitée, il est temps de la vérifier`,
    body: `Retrouve la paie ${formatLink(navigateTo, "sur le back-office", baseUrl)}`,
  }),
  pay_tracking_checked: ({ talent, navigateTo, month }, baseUrl) => ({
    title: `📬 La paie de ${formatOptionalValueWithFunc(talent, getFullName)} pour le mois de ${month} a été vérifiée et est prête à être envoyée`,
    body: `Retrouve la paie ${formatLink(navigateTo, "sur le back-office", baseUrl)}`,
  }),
  timesheet_contact_signer_error: ({ navigateTo, company_name, contact_data, talent_name }, baseUrl) => ({
    title: `❌ Mauvaise coordonnées pour la signature chantier de ${talent_name}`,
    body: `Le talent ${talent_name} a utilisé de mauvaises coordonnées pour signer les RH avec ${company_name} (signature chantier).\nCoordonnées utilisées : email = ${contact_data.email}, nom = ${contact_data.first_name} ${contact_data.last_name}.\n Contrat concerné : ${formatLink(navigateTo, "Clique ici", baseUrl)}`,
  }),
  expense_claim_request_to_confirm_reminder: ({ navigateTo, titleContent, bodyContent }, baseUrl) => ({
    title: `🚨 ACTION REQUISE - ${titleContent}`,
    body: `${bodyContent} ${formatLink(navigateTo, "👉 Direction le back-office!", baseUrl)}`,
  }),
};

// This our main map where we spread the two previous objects.
const notificationTextFormatters: {
  [Type in Database["public"]["Enums"]["notification_type"]]: (
    notificationData: Extract<NotificationData, { type: Type }>["data"],
    baseUrl?: string
  ) => NotificationText;
} = { ...mobileAppNotificationTextFormatters, ...backOfficeNotificationTextFormatters };
